body {
    margin: 0;
    padding: 0;

    font-family: "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    touch-action: manipulation;
}

.flex-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: stretch;
    justify-content: center;
    background-color: black;
}

.navbar {
    color: #dddddd;
}

.default-background {
    background-color: #282c34;
}

.header {
    align-items: flex-start;
    margin-bottom: 10px;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: top;
    margin-top: 0;
    z-index: 2;
}

.content .box {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.content .card {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-color: rgba(0, 0, 0, 0.2);
}

.footer {
    margin-top: auto;
    color: #dddddd;
}

.bg {
    /* Set rules to fill background */
    min-width: 1600px;
    min-height: 100%;

    /* Set up proportionate scaling */
    width: 100%;
    height: 600px;

    /* Set up positioning */
    position: relative;
    left: 0;

    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0; /* To background */
}

body.modal-open {
    overflow: visible;
    padding-right: 0 !important;
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.9s;
}

.card .stars {
    display: flex;
    flex-direction: row;
}

.card-header {
    font-weight: bold;
    text-transform: uppercase;
}

.card .text .gray {
    color: #9B9B9B;
}

.card .stars i:hover {
    color: #282c34;
    transition: 250ms;
}

.card .stars i {
    display: block;
    color: yellow;
    position: relative;
    letter-spacing: 0.1em;
    text-align: center;
    transition: 250ms;
}

.form-group.required.control-label:after {
    color: red;
    content: "*";
    position: absolute;
    margin-left: 3px;
}


.paginationbar {
    padding: 10px;
    display: flex;
    word-wrap: break-word;
    list-style: none;

    justify-content: center;

    background-color: #9B9B9B;
    border-radius: 0 0 5px 5px;
}

.paginationbar li {
    color: black;
    background-color: #f8f9fa;
    padding: 4px 10px;
    text-decoration: none;
    margin: 0 5px;

    border: 1px solid #dddddd;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    vertical-align: center;
}

.paginationbar li.active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}

.paginationbar li.disabled {
    pointer-events: none;
}

.paginationbar a {
    color: black;
    text-decoration: none !important;
    outline: none;
}

.dropdown-item .nav-link {
    color: inherit !important;
}
