.pump-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.pump-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;
}

.pump-item .id {
    width: 10px;
}

.pump-item > div {
    text-align: center;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
}

.pump-item .cutstring {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
