
.connect-box {
    display: flex;
    flex-direction: column;
    max-width: 250px !important;

    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.connect-title {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.connect-text {
    margin-bottom: 5px;
}

.connect-item {
    display: flex;
    flex-direction: row;
}

.connect-item > div {
    margin: 2px;
    display: flex;
    align-items: center;
    width: 75px;
    text-align: center;
}
